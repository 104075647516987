import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'

function useAuthority(userAuthority = [], authority = [], emptyCheck = false) {

	const permissions = useSelector(state => state.auth.session.permissions)

    const roleMatched = useMemo(() => {
		return permissions.some(role => role.authority == authority)
	}, [authority, permissions])
    
    if (isEmpty(authority) || isEmpty(userAuthority) || typeof authority === 'undefined') {
		return !emptyCheck
	}
	
    return roleMatched
}

export default useAuthority