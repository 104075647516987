import { API_BASE_URL } from '../redux/constants/index';

async function handleResponse(response, signOut) {

  switch (response.status) {
    case 200:
      return response.json();
    default:
      await signOut();
      throw new Error(`Unhandled status code: ${response.status}`);
  }
}

export async function AccountSignIn(userName, password) {

  const response = await fetch(API_BASE_URL + 'Account/Login', {
    method: "POST",
    body: JSON.stringify({
      email: userName,
      password: password
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    }
  });
  var data = await response.json();
  return data;
}

export async function AccountSignUp(object) {
  const response = await fetch(API_BASE_URL + 'Account/SignUp', {
    method: "POST",
    body: JSON.stringify({
      firstName: object.firstName,
      lastName: object.lastName,
      email: object.email,
      designation: object.designation,
      organization: object.organization,
      password: object.password,
      phoneNumber: object.phoneNumber,
      gender: object.gender,
      dob: object.dob,
      defaultCurrencyId: object.defaultCurrencyId,
      paymentCurrencyId: object.paymentCurrencyId,
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    }
  });
  var data = await response.json();
  return data;
}

export async function apiChangePassword(object, user, signOut) {

  const response = await fetch(API_BASE_URL + 'Account/ChangePassword', {
    method: "POST",
    body: JSON.stringify({
      email: object.email,
      currentPassword: object.currentPassword,
      newPassword: object.newPassword
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "key": user.id,
      "orgId": user.organizationId,
      "Authorization": 'Bearer ' + user.token
    }
  });
  return handleResponse(response, signOut);
}

export async function apiForgotPassword (object) {

  let formData = new FormData();
  formData.append('Email', object.email);

  const response = await fetch(API_BASE_URL + 'Account/ForgetPassword',
  {
    body: formData,
    method: "post"
  });
  var data = await response.json();
  return data;
}

export async function apiResetPassword (object) {
  
  const response = await fetch(API_BASE_URL + 'Account/SetNewPassword', {
    method: "POST",
    body: JSON.stringify({
      id: object.id,
      token: object.token,
      password: object.password
    }),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    }
  });
  var data = await response.json();
  return data;
}