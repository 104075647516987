import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getFirestore } from 'firebase/firestore/lite'
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithPopup,
} from 'firebase/auth'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

import firebaseConfig from 'configs/firebase.config'

const firebaseApp = initializeApp(firebaseConfig)

// const messaging = getMessaging(firebaseApp);
const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp)
const currentUser = auth.currentUser
const googleProvider = new GoogleAuthProvider()
const facebookProvider = new FacebookAuthProvider()

// // Request permission and get token
// const requestPermissionAndToken = async () => {
//     try {
//         const token = await getToken(messaging, { vapidKey: 'BBvGSp0dTVwGkoZdHJlFDJQK2q5ZzC3lBvK7YzYtGYGfRo4CQL7GQUtPkrH3xIT_VfHQmsWDm4KhExlPdMGwYGE' });
//         console.log('Token received: ', token);
//         // Send token to your server or save it in local storage
//     } catch (error) {
//         console.error('Error getting token: ', error);
//     }
// };

// requestPermissionAndToken();

// // Handle foreground messages
// onMessage(messaging, (payload) => {
//     console.log('Message received. ', payload);
//     // Display notification to user
// });

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/firebase-messaging-sw.js')
//     .then((registration) => {
//         console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//         console.error('Service Worker registration failed:', error);
//     });
// }

export {
    db,
    auth,
    currentUser,
    signInWithEmailAndPassword,
    signOut,
    createUserWithEmailAndPassword,
    googleProvider,
    facebookProvider,
    signInWithPopup,
}
