
const firebaseConfig = {
    apiKey: "AIzaSyCxIUjjJqX8x2pKC_gbk4rtLctV9npDuvQ",
    authDomain: "trackaxo.firebaseapp.com",
    projectId: "trackaxo",
    storageBucket: "trackaxo.appspot.com",
    messagingSenderId: "794158713140",
    appId: "1:794158713140:web:b0cf56a6c649cd105ffd61",
    measurementId: "G-06CKB0C7W9"
};
  
export default firebaseConfig;