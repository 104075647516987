import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignOut } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { AccountSignIn } from 'services/AuthenticationService'
import { auth, googleProvider, signInWithPopup, createUserWithEmailAndPassword, facebookProvider } from 'firebase.js'

function useAuth() {

    const dispatch = useDispatch()

    const navigate = useNavigate()

	const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)
    const signIn = async ({ userName, password }) => {
        try {
			var resp = await AccountSignIn(userName, password);
			
			if (resp.status) {
				dispatch(onSignInSuccess(resp))
				if(resp.data1) {
					dispatch(setUser({ 
						id: resp.data1.id,
						avatar: resp.data1.image, 
						userName: resp.data1.userName, 
						firstName: resp.data1.firstName,
						lastName: resp.data1.lastName,
						designation: resp.data1.designation,
						authority: ['user'], 
						email: resp.data1.email,
						organizationId: resp.data1.organizationId,
						organization: resp.data1.organization,
						token: resp.token
					}))
					localStorage.setItem("User_Info", JSON.stringify(resp.data1.id));
					localStorage.setItem("User_Full_Info", JSON.stringify(resp.data1));
				}
				
				const redirectUrl = !resp.data1.organization.isOrganizationSetup? "/app/welcome": query.get(REDIRECT_URL_KEY)
				navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)
                return {
                    status: 'success',
                    message: ''
                }
			}
			else
			{
				return {
					status: 'failed',
					message: resp.message
				}
			}
		} catch (errors) {
			return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString()
            }
		}
    }

	const googleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider)
            setUser(result.user)
            return { status: 'success' }
        } catch (error) {
			console.log(error.message)
            return { status: 'failed', message: error.message }
        }
    }

	const facebookSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, facebookProvider)
            setUser(result.user)
            return { status: 'success' }
        } catch (error) {
			console.log(error.message)
            return { status: 'failed', message: error.message }
        }
    }

    const handleSignOut = ()  => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(initialState))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

    const signOut = async () => {
		try {
			await apiSignOut()
			handleSignOut()
		} catch (errors) {
			handleSignOut()
		}
	}
    
    return {
        authenticated: token && signedIn,
        signIn,
        signOut,
		googleSignIn,
		facebookSignIn,
		createUserWithEmailAndPassword,
    }
}

export default useAuth